import { LayerSourceType } from 'datacosmos/entities/layer';
import { MapMarkerLayer } from 'datacosmos/entities/mapMarkerLayer';
import { useMapLayers } from 'datacosmos/stores/MapLayersProvider';
import { useMap } from 'datacosmos/stores/MapProvider';
import { Button, Dialog, Input } from 'opencosmos-ui';
import { useState } from 'react';
import { useLocalisation } from 'utils/hooks/useLocalisation';

interface LocationDetailsDialogProps {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  handleUploadWithLocation: (coords: string) => Promise<void>;
  resetUploadFileRef: () => void;
  isUploading: boolean;
}
const LocationDetailsDialog = ({
  isOpen,
  setIsOpen,
  handleUploadWithLocation,
  resetUploadFileRef,
  isUploading,
}: LocationDetailsDialogProps) => {
  const { translate } = useLocalisation();
  const { mapRef } = useMap();
  const { removeLayersBySourceType, addLayer } = useMapLayers();

  const [inputCoordinates, setInputCoordinates] = useState<string>('');

  const handleMarkPointOnMap = () => {
    const map = mapRef.current;
    map?.on('click', (e) => {
      const coords = e.latlng;
      if (!coords) {
        return;
      }
      const marker = new MapMarkerLayer(
        LayerSourceType.UPLOAD_FILE_MARKER,
        '',
        [coords.lng, coords.lat],
        '',
        { enablePopup: false }
      );
      setInputCoordinates(`${coords?.lng}, ${coords?.lat}`);
      removeLayersBySourceType(LayerSourceType.UPLOAD_FILE_MARKER);
      addLayer(marker);
      setIsOpen(true);
    });
    setIsOpen(false);
  };

  return (
    <Dialog
      isOpen={isOpen}
      onClose={() => {
        setIsOpen(false);
        setInputCoordinates('');
        resetUploadFileRef();
        removeLayersBySourceType(LayerSourceType.UPLOAD_FILE_MARKER);
        mapRef?.current?.off('click');
      }}
      buttons={[
        {
          text: translate('datacosmos.buttons.upload'),
          onPress: async () => {
            await handleUploadWithLocation(inputCoordinates);
            setInputCoordinates('');
            removeLayersBySourceType(LayerSourceType.UPLOAD_FILE_MARKER);
            mapRef?.current?.off('click');
          },
          shown: true,
          keepDialogOpenOnPress: true,
          showLoadingIndicator: isUploading,
        },
      ]}
      title={translate('datacosmos.locationDetailsDialog.title')}
      style={{
        maxWidth: '400px',
      }}
      showButtonsInFooter
      cancelButtonText={translate('datacosmos.buttons.cancel')}
    >
      <div className="flex flex-col gap-2">
        <h2 className="font-bold text-lg">
          {translate('datacosmos.locationDetailsDialog.message')}{' '}
        </h2>
        <Input
          type="text"
          placeholder="Eg. 31.87, 45.67"
          label={{
            text: translate('datacosmos.locationDetailsDialog.label'),
            position: 'top',
          }}
          fill
          value={inputCoordinates}
          onChange={(e) => {
            setInputCoordinates(e.target.value);
          }}
        />
        <div className="flex items-center my-2 text-gray-500">
          <div className="flex-grow border-t border-gray-300"></div>
          <span className="px-2">OR</span>
          <div className="flex-grow border-t border-gray-300"></div>
        </div>
        <Button icon="Pin" fill onPress={() => handleMarkPointOnMap()}>
          {translate('datacosmos.locationDetailsDialog.markLocationBtnText')}
        </Button>
      </div>
    </Dialog>
  );
};

export default LocationDetailsDialog;
