import { ActivityStatusList, CloudStatusList } from '_api/activities/types';
import type {
  ActivityStatus,
  Activity,
  ActivityWithRequestData,
  CloudStatus,
} from '_api/activities/types';
import classNames from 'classnames';
import type { CellContext } from 'opencosmos-ui';
import { Button, Table, Tooltip } from 'opencosmos-ui';
import React, { useCallback, useEffect } from 'react';
import InputCellWithPopover from './InputCellWithPopover';
import { useActivities } from '../../context/ActivitiesProvider';
import SelectCell from './SelectCell';
import CommandGroupArgument from 'pages/ops/RTI/Operate/components/CommandArguments/CommandGroupArgument';
import NumberInputCell from './NumberInputCell';
import FullscreenMap from '../ActivitiesMap/FullscreenMap';
import useLocalStorage from 'utils/hooks/useLocalStorage';

type Props = {
  activities: ActivityWithRequestData[];
  onActivitySelect: (activity: Activity | undefined) => void;
  expanded?: boolean;
  hiddenColumns?: string[];
};

// Columns that should not be displayed in the table at all
export const EXCLUDED_COLUMNS = ['parameters.physical'];
// Columns that should be hidden by default
export const DEFAULT_HIDDEN_COLUMNS = [
  'parameters.platform.roll_angle',
  'parameters.imager.mode',
  'parameters.imager.name',
  'parameters.imager.encoding',
  'parameters.imager.band_setup',
  'parameters.imager.line_period_us',
  'parameters.imager.number_of_lines',
  'parameters.imager.exposure_time_us',
  'parameters.imager.acquisition_padded',
  'parameters.imager.compression_factor',
  'parameters.imager.acquisition_end_date',
  'parameters.imager.acquisition_start_date',
  'parameters.imager.predicted_file_size_gb',
  'parameters.metrics.cloud_status',
];

const SchedulingTable = ({ activities, expanded, hiddenColumns }: Props) => {
  const {
    updateActivity,
    updatedActivitiesMap,
    failureReasonSchema,
    requests,
  } = useActivities();

  const [activityToEdit, setActivityToEdit] = React.useState<
    Activity | undefined
  >();

  const [isMapOpen, setIsMapOpen] = React.useState(false);

  const [columnOrder, setColumnOrder] = useLocalStorage<string[]>(
    'schedulingTableColumnOrder',
    []
  );

  const [columnSize, setColumnSize] = useLocalStorage<{
    [key: string]: number;
  }>('schedulingTableColumnSize', {});

  const getSelectIntent = (
    status: ActivityStatus
  ): 'none' | 'success' | 'warning' => {
    switch (status) {
      case 'COMPLETED':
        return 'success';
      case 'CANCELLED':
      case 'FAILED':
      case 'EXPIRED':
        return 'warning';
      default:
        return 'none';
    }
  };

  const renderStatusSelector = (
    ctx: CellContext<ActivityWithRequestData, unknown>
  ) => {
    const updatedActivityStatus =
      updatedActivitiesMap?.[ctx.row.original.id]?.status ??
      (ctx.getValue() as ActivityStatus);

    return (
      <SelectCell
        selectedItemProps={{
          className: classNames({
            'bg-neutral-300 dark:bg-neutral-600':
              updatedActivityStatus === 'PENDING' ||
              updatedActivityStatus === 'APPROVED',
            'bg-neutral-500 dark:bg-neutral-800':
              updatedActivityStatus === 'CONFIRMED' ||
              updatedActivityStatus === 'SCHEDULED' ||
              updatedActivityStatus === 'PROCESSING' ||
              updatedActivityStatus === 'ACQUIRED',
            'bg-success': updatedActivityStatus === 'COMPLETED',
            'bg-warning':
              updatedActivityStatus === 'CANCELLED' ||
              updatedActivityStatus === 'FAILED',
          }),
          intent: getSelectIntent(updatedActivityStatus),
        }}
        items={[...ActivityStatusList]}
        selectedKey={updatedActivityStatus}
        onSelectionChange={(activityStatus) => {
          updateActivity(ctx.row.original.id, {
            status: activityStatus as ActivityStatus,
          });
        }}
      />
    );
  };

  const renderCloudStatus = useCallback(
    (ctx: CellContext<ActivityWithRequestData, unknown>) => {
      const updatedCloudStatus =
        updatedActivitiesMap?.[ctx.row.original.id]?.parameters?.metrics
          ?.cloud_status ?? (ctx.getValue() as CloudStatus);

      return (
        <SelectCell
          items={[...CloudStatusList]}
          selectedKey={updatedCloudStatus}
          onSelectionChange={(cloudStatus) => {
            updateActivity(ctx.row.original.id, {
              parameters: {
                metrics: {
                  cloud_status: cloudStatus as CloudStatus,
                },
              },
            });
          }}
        />
      );
    },
    [updateActivity, updatedActivitiesMap]
  );

  const renderOperatorNotes = (
    ctx: CellContext<ActivityWithRequestData, unknown>
  ) => {
    const updatedOperatorNotes =
      updatedActivitiesMap?.[ctx.row.original.id]?.operator_notes ??
      ctx.row.original.operator_notes;
    return (
      <InputCellWithPopover
        inputFor="operator_notes"
        value={updatedOperatorNotes}
        activityId={ctx.row.original.id}
        updateActivity={updateActivity}
      />
    );
  };

  const renderRequestNote = (
    ctx: CellContext<ActivityWithRequestData, unknown>
  ) => {
    const updatedRequestNote = ctx.row.original.request_note;
    return (
      <InputCellWithPopover
        value={updatedRequestNote}
        activityId={ctx.row.original.id}
        updateActivity={updateActivity}
      />
    );
  };

  const renderStatusReason = (
    ctx: CellContext<ActivityWithRequestData, unknown>
  ) => {
    const activity = ctx.row.original;
    return (
      <div
        className="h-7"
        onClick={(e) => {
          if (e.target instanceof HTMLSelectElement) {
            e.stopPropagation(); // Stop propagation only if the target is a select element
          }
        }}
      >
        {failureReasonSchema.map((property) => {
          return (
            <CommandGroupArgument
              hideInputPath={true}
              key={property.path}
              property={property}
              value={
                updatedActivitiesMap?.[activity.id]?.status_reason ??
                activity.status_reason
              }
              onBlur={() => {}}
              onChange={(reason) => {
                updateActivity(activity.id, {
                  status_reason: reason === 'Select' ? '' : (reason as string),
                });
              }}
              enableAllFields={true}
              disabled={
                activity.status !== 'FAILED' && activity.status !== 'CANCELLED'
              }
            />
          );
        })}
      </div>
    );
  };
  const renderSessionId = (
    ctx: CellContext<ActivityWithRequestData, unknown>
  ) => {
    return (
      <NumberInputCell
        activityId={ctx.row.original.id}
        updateActivity={updateActivity}
        value={
          updatedActivitiesMap?.[ctx.row.original.id]?.parameters?.session
            ?.session_id ?? (ctx.getValue() as number)
        }
      />
    );
  };

  // Hack to prevent scrolling on the html element when the table is rendered
  // This is due to *something* going on with the <Select/> component
  useEffect(() => {
    document.documentElement.style.overflow = 'hidden';
    return () => {
      document.documentElement.style.overflow = '';
    };
  }, []);

  return (
    <div
      className={classNames({
        'w-[47.5vw]': !expanded,
        'w-full': expanded,
      })}
    >
      <Table
        data={activities}
        stickyHeader={true}
        hiddenColumns={hiddenColumns}
        onColumnReorder={(cols) => {
          setColumnOrder(cols);
        }}
        cellRenderer={(ctx, header) => {
          if (header === 'status_reason') {
            return (
              <div
                className={classNames({
                  'border-b-4 border-accent box-border h-9': Boolean(
                    updatedActivitiesMap?.[ctx.row.original.id]
                  ),
                })}
              >
                {renderStatusReason(ctx)}{' '}
              </div>
            );
          }

          if (header === 'parameters.session.session_id') {
            return (
              <div
                className={classNames({
                  'border-b-4 border-accent box-border h-9': Boolean(
                    updatedActivitiesMap?.[ctx.row.original.id]
                  ),
                })}
              >
                {renderSessionId(ctx)}
              </div>
            );
          }

          if (header === 'status') {
            return (
              <div
                className={classNames({
                  'border-b-4 border-accent box-border h-9': Boolean(
                    updatedActivitiesMap?.[ctx.row.original.id]
                  ),
                })}
              >
                {renderStatusSelector(ctx)}
              </div>
            );
          }

          if (header === 'parameters.metrics.cloud_status') {
            return (
              <div
                className={classNames({
                  'border-b-4 border-accent box-border h-9': Boolean(
                    updatedActivitiesMap?.[ctx.row.original.id]
                  ),
                })}
              >
                {renderCloudStatus(ctx)}
              </div>
            );
          }

          if (header === 'operator_notes') {
            return (
              <div
                className={classNames('px-1 flex items-center h-9', {
                  'border-b-4 border-accent box-border h-9': Boolean(
                    updatedActivitiesMap?.[ctx.row.original.id]
                  ),
                })}
              >
                {renderOperatorNotes(ctx)}
              </div>
            );
          }

          if (header === 'request_note') {
            return (
              <div
                className={classNames('px-1 flex items-center h-9', {
                  'border-b-4 border-accent box-border h-9': Boolean(
                    updatedActivitiesMap?.[ctx.row.original.id]
                  ),
                })}
              >
                {renderRequestNote(ctx)}
              </div>
            );
          }

          return (
            <div
              className={classNames('px-1 flex items-center h-9', {
                'border-b-4 border-accent box-border h-9': Boolean(
                  updatedActivitiesMap?.[ctx.row.original.id]
                ),
              })}
            >
              {String(ctx.getValue())}
            </div>
          );
        }}
        showFullHeaderTitle={false}
        excludeDataKeys={EXCLUDED_COLUMNS}
        columnOrder={['edit_activity', ...columnOrder]}
        appendColumns={[
          {
            cell: (ctx) => {
              const shouldDisableBtn =
                ctx.row.original.status === 'CANCELLED' ||
                ctx.row.original.status === 'FAILED' ||
                ctx.row.original.status === 'EXPIRED' ||
                ctx.row.original.status === 'SCHEDULED';

              return (
                <Tooltip
                  content={
                    shouldDisableBtn
                      ? 'Failed, expired, cancelled and scheduled activities cannot be edited'
                      : 'Edit swath'
                  }
                >
                  <Button
                    icon="Pencil"
                    intent="primary"
                    className={'h-full w-8 max-h-full'}
                    onPress={() => {
                      setActivityToEdit(ctx.row.original);
                      setIsMapOpen(true);
                    }}
                    isDisabled={shouldDisableBtn}
                  />
                </Tooltip>
              );
            },
            id: 'edit_activity',
            header: '',
          },
        ]}
        headersToExcludeFromReorder={['edit_activity']}
        // Since edit_activity should not be resizable, we overwrite the columnSize for it with a fixed value
        columnSize={{ edit_activity: 32, ...columnSize }}
        onColumnResize={(colSizes) => {
          setColumnSize(colSizes);
        }}
        headersToExcludeFromResize={['edit_activity']}
      />

      {activityToEdit && (
        <FullscreenMap
          activities={activities.filter((a) =>
            requests
              .find((r) => activityToEdit.tasking_request_ids.includes(r.id))
              ?.activities.some((act) => act.id === a.id)
          )}
          request={requests.find((r) =>
            activityToEdit.tasking_request_ids.includes(r.id)
          )}
          activityToModify={activityToEdit}
          isMapOpen={isMapOpen}
          setIsMapOpen={setIsMapOpen}
          // This triggers on the map close which we don't need
          refetchActivities={async () => {}}
        />
      )}
    </div>
  );
};

export default SchedulingTable;
