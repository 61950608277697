import { useSortable } from "@dnd-kit/sortable";
import { Cell, flexRender } from "@tanstack/react-table";
import classNames from "classnames";
import { CSSProperties } from "react";
import { CSS } from "@dnd-kit/utilities";

type Props<T> = {
  cell: Cell<T, unknown>;
  isReorderable: boolean | undefined;
  isSelectable: boolean;
  isMinimal: boolean;
};

const DragAlongCell = <T,>({
  cell,
  isReorderable,
  isSelectable,
  isMinimal,
}: Props<T>) => {
  const { isDragging, setNodeRef, transform } = useSortable({
    id: cell.column.id,
  });

  const style: CSSProperties = {
    opacity: isDragging ? 0.8 : 1,
    position: "relative",
    transform: CSS.Translate.toString(transform), // translate instead of transform to avoid squishing
    transition: "width transform 0.2s ease-in-out",
    width: isReorderable ? cell.column.getSize() : "fit-content",
    zIndex: isDragging ? 1 : 0,
  };

  return (
    <td
      ref={setNodeRef}
      style={style}
      {...{
        key: cell.id,
      }}
      className={classNames("p-0 h-8 break-all", {
        "!cursor-pointer": isSelectable,
        "border border-item-contrast-inactive": !isMinimal,
      })}
    >
      {flexRender(cell.column.columnDef.cell, cell.getContext())}
    </td>
  );
};

export default DragAlongCell;
