import { DATETIME_FORMAT } from 'constants/datetime';
import { BandAlgebraSTACLayer } from 'datacosmos/entities/bandAlgebraLayer';
import { CircleLayer } from 'datacosmos/entities/circleLayer';
import { FieldOfRegardLayer } from 'datacosmos/entities/FieldOfRegardLayer';
import { LayerSourceType, type Layer } from 'datacosmos/entities/layer';
import { LineLayer } from 'datacosmos/entities/lineLayer';
import { OutlineLayer } from 'datacosmos/entities/outlineLayer';
import { PolygonLayer } from 'datacosmos/entities/polygonLayer';
import { SingleBandSTACLayer } from 'datacosmos/entities/singleBandLayer';
import { STACLayerFactory } from 'datacosmos/entities/stacLayer';
import { SwathLayer } from 'datacosmos/entities/SwathLayer';
import { OpportunityLayer } from 'datacosmos/entities/TaskingOpportunityLayer';
import { useMapLayers } from 'datacosmos/stores/MapLayersProvider';
import { useMap } from 'datacosmos/stores/MapProvider';
import { StacItem } from 'datacosmos/types/stac-types';
import { isAssetPreviewable } from 'datacosmos/utils/stac';
import moment from 'moment';
import { Item } from 'react-stately';
import ListItem from '_atoms/ListItem/ListItem';
import Select2 from '_molecules/Select2/Select2';
import ListCard from '_organisms/ListCard/ListCard';
import useStacInfo from '../../../utils/hooks/useStacInfo';
import bbox from '@turf/bbox';
import { useLocalisation } from 'utils/hooks/useLocalisation';
import type { DownloadType } from './helpers';
import { getDownloadTypes } from './helpers';
import { useState } from 'react';
import { useAnalytics } from 'utils/hooks/analytics/useAnalytics';
import { useFilters } from 'datacosmos/stores/FiltersProvider';
import type { CommonOpportunity } from '_api/tasking/helpers';
import { Tooltip, Button } from 'opencosmos-ui';

type IProps = {
  layer: Layer;
  readOnly?: boolean;
};

const LayerListCard = ({ layer, readOnly }: IProps) => {
  const { replaceLayer, removeLayer } = useMapLayers();
  const { disableEditing, setViewToFitBbox } = useMap();

  const { replaceSpecificLayerWithAnother } = useMapLayers();

  const { translate } = useLocalisation();

  const { sendInfo } = useAnalytics();

  const { areaOfInterest, setAreaOfInterest } = useFilters();

  const [selectedDlType, setSelectedDlType] =
    useState<DownloadType['ext']>('kml');

  const renderDownloadAsFormat = (
    l: LineLayer | PolygonLayer | CircleLayer
  ) => (
    <Select2
      items={getDownloadTypes(l)}
      selectedKey={selectedDlType}
      onSelectionChange={(k) => setSelectedDlType(k as DownloadType['ext'])}
    >
      {(item) => (
        <Item key={item.ext}>
          <div className="flex items-center justify-between gap-4">
            <span>Download as {item.ext}</span>
            <Button
              icon="Download"
              onPress={() => item.handler()}
              isMinimal
              isTransparent
            />
          </div>
        </Item>
      )}
    </Select2>
  );

  const renderStacItemLayerCard = (iLayer: SingleBandSTACLayer) => {
    const {
      handleBandRemove,
      handleBandSelect,
      sunElevationAngle,
      selectedBands,
      getSelectedAsset,
      // eslint-disable-next-line react-hooks/rules-of-hooks
    } = useStacInfo(iLayer);

    return (
      <ListCard
        title={moment(iLayer.item.date()).format('YYYY MMM DD')}
        info={
          <>
            <ListItem
              icon="Satellite"
              content={iLayer.item.title()}
              className="text-item-contrast-inactive"
              onClick={() => {}}
            />
            {layer instanceof BandAlgebraSTACLayer ? (
              <ListItem
                icon="BandAlgebra"
                content={layer.expression}
                className="text-item-contrast-inactive"
                onClick={() => {}}
              />
            ) : (
              <ListItem
                icon="SunAngle"
                content={sunElevationAngle}
                className="text-item-contrast-inactive"
                onClick={() => {}}
              />
            )}
          </>
        }
        rightIcons={[
          {
            icon: layer.options.visible ? 'Eye' : 'EyeCrossed',
            onClick: () => {
              replaceLayer(
                layer.cloneWithOptions({
                  visible: !layer.options.visible,
                })
              );
            },
            shown: true,
          },
          {
            icon: 'Center',
            onClick: () => {
              setViewToFitBbox(iLayer.item.bbox);
            },
            shown: true,
          },
          {
            icon: 'Cross',
            onClick: () => {
              removeLayer(layer.id);
            },
            shown: true,
            isDisabled: readOnly,
          },
        ]}
        actionElement={
          <Select2
            items={Object.entries(iLayer.item.assets).filter(([, asset]) =>
              isAssetPreviewable(asset)
            )}
            onSelectionChange={(key) => {
              if (selectedBands.find((b) => b[0] === key)) {
                handleBandRemove(selectedBands.find((b) => b[0] === key));
              } else {
                handleBandSelect(
                  Object.entries(iLayer.item.assets).find(
                    (item) => item[0] === key
                  )
                );
              }
              const l = STACLayerFactory({
                item: new StacItem(iLayer.item),
                assetKey: key.toString(),
                expression: undefined,
              });
              replaceSpecificLayerWithAnother(l, layer, true);
            }}
            selectedItem={getSelectedAsset()}
            fill
          >
            {(item) => (
              <Item key={item[0]}>
                <div className="flex items-center justify-between gap-1">
                  <div className="flex flex-col">
                    <span>{item[1].title ?? item[0]}</span>
                    {item[1].title && (
                      <span className="text-xs">{item[0]}</span>
                    )}
                  </div>
                  <Tooltip
                    content={translate(
                      'datacosmos.tooltips.catalogAndItems.downloadAsset'
                    )}
                  >
                    <Button
                      icon="Download"
                      isMinimal
                      isTransparent
                      onPress={() => {
                        window.open(item[1].href, '_blank');

                        sendInfo({
                          action: `Download asset: ${
                            item[1].title ?? item[0]
                          }}`,
                          item: 'Download asset button',
                          type: 'Click',
                          module: 'DataCosmos',
                          download: item[1].href,
                          additionalParams: {
                            asset: item[1].title ?? item[0],
                          },
                        });
                      }}
                    />
                  </Tooltip>
                </div>
              </Item>
            )}
          </Select2>
        }
        indicators={[
          {
            icon: 'ImageBrightness',
            shown: iLayer.isBrightnessApplied?.(),
          },
          {
            icon: 'ImageSaturation',
            shown: iLayer.isSaturationApplied?.(),
          },
          { icon: 'ImageContrast', shown: iLayer.isContrastApplied?.() },
        ]}
      />
    );
  };

  const renderOpportunityLayerCard = (
    iLayer: OpportunityLayer<CommonOpportunity>
  ) => (
    <ListCard
      title={
        iLayer.metadata?.OpportunityIndex !== undefined
          ? `#${iLayer.metadata?.OpportunityIndex} ` + layer.getName()
          : layer.getName()
      }
      info={
        <>
          <ListItem
            content={layer.getDescription() ?? '-'}
            onClick={() => {}}
          />
          <ListItem
            content={
              iLayer?.getStartTime()
                ? moment(iLayer.getStartTime()).utc().format(DATETIME_FORMAT)
                : null
            }
            onClick={() => {}}
          />
        </>
      }
      rightIcons={[
        {
          icon: layer.options.visible ? 'Eye' : 'EyeCrossed',
          onClick: () => {
            replaceLayer(
              layer.cloneWithOptions({
                visible: !layer.options.visible,
              })
            );
          },
          shown: true,
        },
        {
          icon: 'Center',
          onClick: () => {
            setViewToFitBbox(bbox(iLayer.data));
          },
          shown: true,
        },
        {
          icon: 'Cross',
          onClick: () => {
            removeLayer(layer.id);
          },
          shown: true,
        },
      ]}
    />
  );

  const renderLineAndPolygonLayerCards = (iLayer: LineLayer | PolygonLayer) => (
    <ListCard
      title={layer.getName()}
      info={
        <>
          <ListItem
            content={layer.getDescription() ?? '-'}
            onClick={() => {}}
          />
          <ListItem content={iLayer.getMeasurements()} onClick={() => {}} />
        </>
      }
      rightIcons={[
        {
          icon: layer.options.visible ? 'Eye' : 'EyeCrossed',
          onClick: () => {
            replaceLayer(
              layer.cloneWithOptions({
                visible: !layer.options.visible,
              })
            );
          },
          shown: true,
        },
        {
          icon: 'Center',
          onClick: () => {
            setViewToFitBbox(bbox(iLayer.data));
          },
          shown: true,
        },
        {
          icon: 'Cross',
          onClick: () => {
            removeLayer(iLayer.id);
            const shouldRemoveEditingPolygonLayer =
              iLayer instanceof PolygonLayer;
            if (shouldRemoveEditingPolygonLayer) {
              disableEditing(iLayer.getLeafletLayerMetadata(), {
                toggleEditingRef: shouldRemoveEditingPolygonLayer,
              });
              if (
                iLayer.sourceType === LayerSourceType.AREA_OF_INTEREST &&
                areaOfInterest
              ) {
                setAreaOfInterest(undefined);
              }
            }
          },
          shown: true,
        },
      ]}
      actionElement={renderDownloadAsFormat(iLayer)}
    />
  );

  const renderCircleLayerCard = (iLayer: CircleLayer) => (
    <ListCard
      title={iLayer.getName()}
      info={
        <>
          <ListItem
            content={iLayer.getDescription() ?? '-'}
            onClick={() => {}}
          />
          <ListItem content={iLayer.getMeasurements()} onClick={() => {}} />
          <ListItem content={iLayer.getRadius()} onClick={() => {}} />
        </>
      }
      rightIcons={[
        {
          icon: iLayer.options.visible ? 'Eye' : 'EyeCrossed',
          onClick: () => {
            replaceLayer(
              iLayer.cloneWithOptions({
                visible: !iLayer.options.visible,
              })
            );
          },
          shown: true,
        },
        {
          icon: 'Center',
          onClick: () => {
            setViewToFitBbox(bbox(iLayer.data));
          },
          shown: true,
        },
        {
          icon: 'Cross',
          onClick: () => {
            removeLayer(iLayer.id);
            disableEditing(iLayer.getLeafletLayerMetadata(), {
              toggleEditingRef: true,
            });
            if (
              iLayer.sourceType === LayerSourceType.AREA_OF_INTEREST &&
              areaOfInterest
            ) {
              setAreaOfInterest(undefined);
            }
          },
          shown: true,
        },
      ]}
      actionElement={renderDownloadAsFormat(iLayer)}
    />
  );

  const renderUnknownLayerCard = (iLayer: Layer) => (
    <ListCard
      title={iLayer.getName()}
      info={
        <ListItem
          icon="Info"
          content={iLayer.getDescription()}
          onClick={() => {}}
        />
      }
      rightIcons={[
        {
          icon: iLayer.options.visible ? 'Eye' : 'EyeCrossed',
          onClick: () => {
            replaceLayer(
              iLayer.cloneWithOptions({
                visible: !iLayer.options.visible,
              })
            );
          },
          shown: true,
        },
      ]}
    />
  );

  if (
    layer instanceof SingleBandSTACLayer ||
    layer instanceof BandAlgebraSTACLayer ||
    layer instanceof OutlineLayer
  ) {
    const iLayer = layer;
    return renderStacItemLayerCard(iLayer as SingleBandSTACLayer);
  } else if (
    layer instanceof OpportunityLayer ||
    layer instanceof FieldOfRegardLayer ||
    layer instanceof SwathLayer
  ) {
    const iLayer = layer as OpportunityLayer<CommonOpportunity>;
    return renderOpportunityLayerCard(iLayer);
  } else if (layer instanceof LineLayer || layer instanceof PolygonLayer) {
    const iLayer = layer as LineLayer;
    return renderLineAndPolygonLayerCards(iLayer);
  } else if (layer instanceof CircleLayer) {
    return renderCircleLayerCard(layer);
  }

  return renderUnknownLayerCard(layer);
};

export default LayerListCard;
